"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = downloadShowPlaylist;
const react_toastify_1 = require("react-toastify");
function niceString(input) {
    return input.replace(/[^a-zA-Z0-9.]/g, '_');
}
function downloadPlaylist(title, text, profile) {
    try {
        const blob = new Blob([text], {
            type: 'text/plain',
        });
        const dUrl = window.URL.createObjectURL(blob);
        const fileTitle = niceString(title);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', dUrl);
        link.setAttribute('download', `${fileTitle}_${profile}.m3u8`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    catch (e) {
        react_toastify_1.toast.error("Can't generate playlist");
    }
}
function downloadShowPlaylist(conf) {
    const { title, item, files, t, auth, profile } = conf;
    const sN = t.get('page.watch.label.season');
    const eN = t.get('page.watch.label.episode');
    const lines = ['#EXTM3U', `#PLAYLIST:${title}`];
    const prof = profile !== 'web' ? `?profile=${profile}` : '';
    files.forEach((s) => {
        s.episode.forEach((e) => {
            lines.push(`#EXTINF:-1, ${sN} ${e.show_season} ${eN} ${e.show_episode} - ${niceString(e.episode_title || '')}`);
            lines.push(auth(`/s/watch-service/show/stream/${item}/e/${e.e_id}${prof}`, true, true));
        });
    });
    downloadPlaylist(title, lines.join('\n'), profile);
}
