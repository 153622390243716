"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'profile',
    description: 'User Profile',
    icon: 'IOPeopleCircle',
    view: lib_1.TabView.PROFILE,
    menu: 'bot',
    action: ModAction_1.AddTabAction,
};
exports.default = mod;
