"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTMDBShowMap = isTMDBShowMap;
exports.filterShowMap = filterShowMap;
function isTMDBShowMap(showMap) {
    return showMap.e_id === null;
}
function filterShowMap(showMap) {
    return showMap.filter((show) => !isTMDBShowMap(show));
}
