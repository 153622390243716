"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'playlist',
    description: 'Playlist',
    icon: 'IOPlayCircle',
    menu: 'top',
    panel: lib_1.PanelViews.PLAYLIST,
    action: ModAction_1.AddPanelAction,
};
exports.default = mod;
