"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useTranscode;
const GlobalContext_1 = require("@/context/GlobalContext");
const hooks_1 = require("@/store/hooks");
function useTranscode() {
    const isAdmin = (0, hooks_1.useIsAdmin)();
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [convertStatus, , reloadStatus] = (0, hooks_1.useTabQData)('transcodeData', async () => {
        if (!isAdmin)
            return [];
        return (await context.getTranscodeList()).data;
    });
    return {
        isAdmin,
        convertStatus,
        reloadStatus,
    };
}
