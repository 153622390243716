"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const dashjs_1 = require("dashjs");
const react_components_1 = require("@grandlinex/react-components");
const PlayerV4Context_1 = require("@/component/player/PlayerV4Context");
const store_1 = require("@/store");
function updateContext(player, o) {
    let txt = o.text;
    if (player.isTextEnabled() && o.text !== undefined) {
        txt = player.getCurrentTrackFor('text');
    }
    return {
        video: o.video ?? player.getCurrentTrackFor('video'),
        audio: o.audio ?? player.getCurrentTrackFor('audio'),
        text: txt || null,
        videoTracks: o.videoTracks ?? player.getTracksFor('video'),
        audioTracks: o.audioTracks ?? player.getTracksFor('audio'),
        textTracks: o.textTracks ?? player.getTracksFor('text'),
        currentVideoQuality: o.currentVideoQuality ?? player.getQualityFor('video'),
    };
}
const AwesomePlayer = (0, react_1.forwardRef)(({ url, onPause, onEnded, onReady, onPlay, onSeek, loop, playing, volume, onProgress, muted, width, height, controls, }, ref) => {
    const md = (0, react_1.useMemo)(() => (0, react_components_1.md5)(url), [url]);
    const playerRef = (0, react_1.createRef)();
    const token = (0, store_1.useAppSelector)(store_1.selectToken);
    const playerContext = (0, PlayerV4Context_1.usePlayerContext)();
    const [curUrl, setCurUrl] = (0, react_1.useState)(url);
    const [playedSec, setPlayedSec] = (0, react_1.useState)(0);
    const [p, setPlayer] = (0, react_1.useState)(null);
    const [seekTo, setSeekTo] = (0, react_1.useState)(null);
    (0, react_1.useImperativeHandle)(ref, () => ({
        getDuration() {
            return p?.duration() ?? null;
        },
        getCurrentTime() {
            return playedSec;
        },
        getBufferLength() {
            return p?.getBufferLength('video') || null;
        },
        seekTo(time) {
            p?.seek(time);
        },
        setVideoBitrate(bitrateIndex) {
            if (p) {
                if (bitrateIndex === -1) {
                    const settings = {
                        streaming: {
                            abr: {
                                autoSwitchBitrate: { video: true },
                            },
                        },
                    };
                    /* console.log(playerContext, settings); */
                    p.updateSettings(settings);
                }
                else {
                    const settings = {
                        streaming: {
                            abr: {
                                autoSwitchBitrate: { video: false },
                            },
                        },
                    };
                    p.updateSettings(settings);
                    p.setQualityFor('video', bitrateIndex);
                }
            }
        },
        setAudioTrack(trackIndex) {
            if (p) {
                p.setCurrentTrack(playerContext.audioTracks[trackIndex]);
                playerContext.setData(updateContext(p, {
                    audio: playerContext.audioTracks[trackIndex],
                }));
            }
        },
        setVideoTrack(trackIndex) {
            if (p) {
                const track = p
                    .getTracksFor('video')
                    .find((e) => e.index === trackIndex);
                p.setQualityFor('video', 0);
                p.setCurrentTrack(track);
                playerContext.setData(updateContext(p, {
                    video: track,
                }));
            }
        },
        setTextTrack(trackIndex) {
            if (p) {
                if (trackIndex === -1) {
                    p.setTextTrack(-1);
                    playerContext.setData(updateContext(p, {
                        text: null,
                    }));
                    return;
                }
                const track = playerContext.textTracks.find((e) => e.index === trackIndex);
                const trackIDX = playerContext.textTracks.findIndex((e) => e.index === trackIndex);
                p.setCurrentTrack(track);
                p.setTextTrack(trackIDX);
                playerContext.setData(updateContext(p, {
                    text: track,
                }));
            }
        },
        getPlayer() {
            return p;
        },
    }));
    (0, react_1.useEffect)(() => {
        if (seekTo !== null) {
            p?.seek(seekTo);
            setSeekTo(null);
        }
    }, [p, playerRef, seekTo]);
    (0, react_1.useEffect)(() => {
        if (playing) {
            p?.play();
        }
        else {
            p?.pause();
        }
    }, [p, playerRef, playing]);
    (0, react_1.useEffect)(() => {
        p?.setVolume(volume ?? 1);
    }, [p, playing, volume]);
    (0, react_1.useEffect)(() => {
        if (!p || curUrl !== url) {
            if (curUrl !== url && p) {
                p.destroy();
            }
            setCurUrl(url);
            const player = (0, dashjs_1.MediaPlayer)().create();
            playerContext.setData({
                video: null,
                audio: null,
                text: null,
                videoTracks: [],
                audioTracks: [],
                textTracks: [],
                currentVideoQuality: 0,
            });
            player.extend('RequestModifier', () => {
                return {
                    modifyRequestHeader(xhr) {
                        xhr.setRequestHeader('Authorization', token);
                        return xhr;
                    },
                };
            }, true);
            player.setCustomInitialTrackSelectionFunction((e) => {
                if (e.length > 1 && e[0]?.type === 'video') {
                    const ix = e.findIndex((el) => el.codec?.includes('hvc1'));
                    if (ix === 0) {
                        const mtmp = e[0];
                        // eslint-disable-next-line prefer-destructuring
                        e[0] = e[1];
                        e[1] = mtmp;
                    }
                }
                if (e.length > 1 && e[0]?.type === 'audio') {
                    const ix = e.findIndex((el) => ['de', 'DE', 'ger', 'GER', 'deu', 'DEU'].includes(el.lang || 'NONE'));
                    if (ix !== 0) {
                        const mtmp = e[ix];
                        // eslint-disable-next-line prefer-destructuring
                        e[ix] = e[0];
                        e[0] = mtmp;
                    }
                }
                console.log('INITIAL TRACK SELECTION', e);
                return e;
            });
            console.log(`#### CREATE PLAYER ${player.getVersion()} ####`);
            player.initialize(document.getElementById('dash-player'), url, true);
            player.on('playbackTimeUpdated', (e) => {
                setPlayedSec(e.time ?? 0);
                onProgress?.({
                    event: 'playbackTimeUpdated',
                    data: {
                        time: e.time ?? 0,
                        timeToEnd: e.timeToEnd,
                    },
                    player,
                });
            });
            player.on('streamInitialized', (e) => {
                console.log(e);
                console.log('video', player.getTracksFor('video'));
                console.log('audio', player.getTracksFor('audio'));
                console.log('text', player.getTracksFor('text'));
                onReady?.({
                    event: 'streamInitialized',
                    data: {
                        seek: (time) => {
                            setSeekTo(time);
                        },
                    },
                    player,
                });
                player.setTextTrack(-1);
                playerContext.setData(updateContext(player, {
                    text: null,
                }));
            });
            player.on('qualityChangeRequested', (e) => {
                playerContext.setData(updateContext(player, {
                    currentVideoQuality: e.newQuality,
                }));
            });
            player.on('playbackPaused', (e) => {
                onPause?.({
                    event: 'playbackPaused',
                    data: e,
                    player,
                });
                if (e.ended) {
                    onEnded?.({
                        event: 'playbackPaused',
                        data: e,
                        player,
                    });
                }
            });
            player.on('playbackSeeking', () => {
                onSeek?.({
                    event: 'playbackSeeking',
                    data: null,
                    player,
                });
            });
            player.on('playbackPlaying', (e) => {
                onPlay?.({
                    event: 'playbackPlaying',
                    data: e,
                    player,
                });
            });
            console.log('#### CREATE PLAYER DONE ####');
            setPlayer(player);
        }
    }, [
        p,
        url,
        playerRef,
        curUrl,
        onPause,
        onProgress,
        onReady,
        onEnded,
        onSeek,
        onPlay,
        playerContext,
        token,
    ]);
    return (react_1.default.createElement("video", { key: `dash-player_${md}`, id: "dash-player", ref: playerRef, controls: controls, width: width, height: height, loop: loop, muted: muted, crossOrigin: "anonymous" }));
});
exports.default = AwesomePlayer;
