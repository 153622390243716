"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useWatchHook;
const store_1 = require("@/store");
const GlobalContext_1 = require("@/context/GlobalContext");
function useWatchHook() {
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [shows, , showReload] = (0, store_1.useTabQData)('shows', async () => {
        return (await context.getShows()).data;
    });
    const [movies, , movieReload] = (0, store_1.useTabQData)('movies', async () => {
        return (await context.getMovies()).data;
    });
    return {
        shows,
        showReload,
        movies,
        movieReload,
    };
}
