"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDashTemplate = isDashTemplate;
exports.getDashDuration = getDashDuration;
exports.getFps = getFps;
exports.getRepresentation = getRepresentation;
exports.isProbeInfo = isProbeInfo;
function isDashTemplate(arg) {
    if (typeof arg !== 'object' || arg === null || arg === undefined) {
        return false;
    }
    const t = arg;
    return t.Period !== undefined && typeof t.atr_type === 'string';
}
function getDashDuration(arg) {
    let duration = 0;
    let tString = arg.atr_mediaPresentationDuration;
    const start = tString.lastIndexOf('T');
    tString = tString.substring(start + 1);
    console.log(tString);
    const parts = tString.split(/[HMS]/gm).filter((el) => el !== '');
    console.log(parts);
    if (parts.length === 3) {
        duration = parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
    }
    if (parts.length === 2) {
        duration = parseInt(parts[0], 10);
    }
    return duration;
}
function getFps(arg) {
    let cur = 0;
    const parts = arg.atr_frameRate?.split('/');
    if (parts) {
        cur = parseInt(parts[0], 10) / (parseInt(parts[1], 10) || 1);
    }
    return Math.trunc(cur);
}
function getRepresentation(arg) {
    if (Array.isArray(arg.Representation)) {
        return arg.Representation;
    }
    return [arg.Representation];
}
function isProbeInfo(arg) {
    if (typeof arg !== 'object' || arg === null || arg === undefined) {
        return false;
    }
    const t = arg;
    return t.format !== undefined && Array.isArray(t.streams);
}
