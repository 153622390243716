"use strict";
// Mainframe Store
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectBlockingModal = exports.selectTabsLeft = exports.selectTabsRight = exports.selectPlayerFullScreen = exports.selectCurrentTabRight = exports.selectCurrentTabLeft = exports.selectCurrentTab = exports.selectSpotlight = exports.selectFeatures = exports.selectShowTask = exports.selectSaveTab = exports.selectUpdateCounter = exports.selectCustomTab = exports.selectSideBar = exports.closeOther = exports.moveTab = exports.setShowTask = exports.setCustomTab = exports.setSaveTab = exports.setUpdateCounter = exports.setFeatures = exports.setSpotlight = exports.closeCurrentTab = exports.addReader = exports.setPlayerFullScreen = exports.setCurrentTab = exports.setBlockingModal = exports.addTab = exports.updateCurrentTabData = exports.closeTab = exports.setSidePanel = exports.mfState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_components_1 = require("@grandlinex/react-components");
const lib_1 = require("@/lib");
const initialMFEnv = {
    sidePanel: null,
    tabsLeft: [
        {
            key: 'home',
            view: lib_1.TabView.HOME,
            titel: 'Home',
            icon: 'IOHome',
        },
    ],
    tabsRight: [],
    currentTabLeft: 0,
    currentTabRight: 0,
    blockingModal: null,
    playerFullScreen: false,
    spotlight: false,
    features: [],
    updateCounter: null,
    saveTabs: react_components_1.LocalStorage.flagLoad('cache_tab'),
    showTask: react_components_1.LocalStorage.flagLoad('show_task'),
    customTabs: null,
};
if (initialMFEnv.saveTabs) {
    const restore = react_components_1.LocalStorage.jsonLoad('tab_data');
    if (restore) {
        initialMFEnv.currentTabRight = restore.currentTabRight;
        initialMFEnv.currentTabLeft = restore.currentTabLeft;
        initialMFEnv.tabsRight = restore.tabsRight;
        initialMFEnv.tabsLeft = restore.tabsLeft;
    }
}
function updateTitle(state, right = false) {
    if (state.tabsLeft.length === 0 && state.tabsRight.length === 0) {
        document.title = 'Echo4';
    }
    else if (right && state.tabsRight.length !== 0) {
        document.title = `${state.tabsRight[state.currentTabRight]?.titel || ''} 🞄 Echo4`;
    }
    else {
        document.title = `${state.tabsLeft[state.currentTabLeft]?.titel || ''} 🞄 Echo4`;
    }
}
exports.mfState = (0, toolkit_1.createSlice)({
    name: 'mainframe',
    initialState: initialMFEnv,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPlayerFullScreen: (state, action) => {
            state.playerFullScreen = action.payload;
        },
        setShowTask: (state, action) => {
            state.showTask = action.payload;
        },
        setUpdateCounter: (state, action) => {
            state.updateCounter = action.payload;
        },
        setCustomTab: (state, action) => {
            state.customTabs = action.payload;
        },
        setSaveTab: (state, action) => {
            if (action.payload !== state.saveTabs) {
                if (!action.payload) {
                    react_components_1.LocalStorage.delete('tab_data');
                }
            }
            state.saveTabs = action.payload;
        },
        setFeatures: (state, action) => {
            state.features = action.payload;
        },
        setBlockingModal: (state, action) => {
            state.blockingModal = action.payload;
        },
        setSidePanel: (state, action) => {
            state.sidePanel = action.payload;
        },
        setSpotlight: (state, action) => {
            state.spotlight = action.payload;
        },
        setCurrentTab: (state, action) => {
            state.playerFullScreen = false;
            if (action.payload.pos === 'right') {
                state.currentTabRight = action.payload.val;
            }
            else {
                state.currentTabLeft = action.payload.val;
            }
            updateTitle(state, action.payload.pos === 'right');
            state.playerFullScreen = false;
        },
        closeOther: (state, action) => {
            const { key, dest } = action.payload;
            if (dest === 'left') {
                state.currentTabLeft = 0;
                state.tabsLeft = state.tabsLeft.filter((e) => e.key === key);
            }
            else {
                state.currentTabRight = 0;
                state.tabsRight = state.tabsRight.filter((e) => e.key === key);
            }
        },
        moveTab: (state, action) => {
            const { key, dest, target, position } = action.payload;
            let tabsLeft = [];
            let tabsRight = [];
            let item;
            let tabsR = state.currentTabRight;
            let tabsL = state.currentTabLeft;
            if (dest === 'left') {
                item = state.tabsLeft.find((e) => e.key === key);
                tabsLeft = state.tabsLeft.filter((e) => e.key !== key);
                tabsRight = state.tabsRight;
            }
            else {
                item = state.tabsRight.find((e) => e.key === key);
                tabsRight = state.tabsRight.filter((e) => e.key !== key);
                tabsLeft = state.tabsLeft;
            }
            if (!item) {
                console.error('Tab not found');
                return;
            }
            if (target === 'left') {
                if (position !== undefined) {
                    tabsLeft = [
                        ...tabsLeft.slice(0, position),
                        item,
                        ...tabsLeft.slice(position),
                    ];
                }
                else {
                    tabsLeft = [...tabsLeft, item];
                }
            }
            if (target === 'right') {
                if (position !== undefined) {
                    tabsRight = [
                        ...tabsRight.slice(0, position),
                        item,
                        ...tabsRight.slice(position),
                    ];
                }
                else {
                    tabsRight = [...tabsRight, item];
                }
            }
            if (!tabsLeft[tabsL]) {
                const ntab = state.currentTabLeft - 1;
                tabsL = ntab < 0 ? 0 : ntab;
            }
            if (!tabsRight[tabsR]) {
                const ntab = state.currentTabRight - 1;
                tabsR = ntab < 0 ? 0 : ntab;
            }
            state.currentTabLeft = tabsL;
            state.tabsLeft = tabsLeft;
            state.currentTabRight = tabsR;
            state.tabsRight = tabsRight;
        },
        addTab: (state, action) => {
            const { item, pos } = action.payload;
            state.playerFullScreen = false;
            let exist = -1;
            if ([
                lib_1.TabView.HOME,
                lib_1.TabView.SETTINGS,
                lib_1.TabView.ADMIN,
                lib_1.TabView.PROFILE,
                lib_1.TabView.FEED_LIST,
                lib_1.TabView.GAMES,
                lib_1.TabView.WATCH,
            ].includes(item.view)) {
                if (pos === 'right') {
                    exist = state.tabsRight.findIndex((tab) => tab.view === item.view);
                    if (exist >= 0) {
                        state.currentTabRight = exist;
                        return;
                    }
                }
                else {
                    exist = state.tabsLeft.findIndex((tab) => tab.view === item.view);
                    if (exist >= 0) {
                        state.currentTabLeft = exist;
                        return;
                    }
                }
            }
            if (pos === 'right') {
                const tabList = [...state.tabsRight, item];
                state.tabsRight = tabList;
                state.currentTabRight = tabList.length - 1;
            }
            else {
                const tabList = [...state.tabsLeft, item];
                state.tabsLeft = tabList;
                state.currentTabLeft = tabList.length - 1;
            }
            updateTitle(state, pos === 'right');
        },
        closeTab: (state, action) => {
            if (action.payload.pos === 'right') {
                const tabList = state.tabsRight.filter(({ key }) => key !== action.payload.val);
                state.tabsRight = tabList;
                if (tabList.length >= state.currentTabRight) {
                    state.currentTabRight = tabList.length - 1;
                }
            }
            else {
                const tabList = state.tabsLeft.filter(({ key }) => key !== action.payload.val);
                state.tabsLeft = tabList;
                if (tabList.length >= state.currentTabLeft) {
                    state.currentTabLeft = tabList.length - 1;
                }
            }
            updateTitle(state, action.payload.pos === 'right');
        },
        closeCurrentTab: (state, action) => {
            if (action.payload.pos === 'left' || state.tabsRight.length === 0) {
                const sel = state.tabsLeft[state.currentTabLeft];
                if (sel) {
                    const cur = sel.key;
                    const tabList = state.tabsLeft.filter(({ key }) => key !== cur);
                    state.tabsLeft = tabList;
                    if (tabList.length >= state.currentTabLeft) {
                        state.currentTabLeft = tabList.length - 1;
                    }
                }
            }
            else {
                const sel = state.tabsRight[state.currentTabRight];
                if (sel) {
                    const cur = sel.key;
                    const tabList = state.tabsRight.filter(({ key }) => key !== cur);
                    state.tabsRight = tabList;
                    if (tabList.length >= state.currentTabRight) {
                        state.currentTabRight = tabList.length - 1;
                    }
                }
            }
            updateTitle(state, action.payload.pos === 'right');
        },
        addReader: (state, action) => {
            state.playerFullScreen = false;
            const tab = {
                view: action.payload.media ? lib_1.TabView.MREADER : lib_1.TabView.READER,
                key: (0, react_components_1.uuid)(),
                icon: 'IOBook',
                data: {
                    reader: action.payload.item,
                },
                titel: action.payload.item.entity.title,
            };
            if (action.payload.pos === 'right') {
                const tabList = [...state.tabsRight, tab];
                state.tabsRight = tabList;
            }
            else {
                const tabList = [...state.tabsLeft, tab];
                state.tabsLeft = tabList;
            }
        },
        updateCurrentTabData: (state, action) => {
            let tabItem;
            if (action.payload.pos === 'right') {
                tabItem = state.tabsRight.find(({ key }) => key === state.tabsRight[state.currentTabRight].key);
            }
            else {
                tabItem = state.tabsLeft.find(({ key }) => key === state.tabsLeft[state.currentTabLeft].key);
            }
            if (tabItem) {
                if (!tabItem.data) {
                    const cur = {};
                    cur[action.payload.key] = action.payload.data;
                    tabItem.data = cur;
                }
                else {
                    tabItem.data[action.payload.key] = action.payload.data;
                }
            }
        },
    },
});
_a = exports.mfState.actions, exports.setSidePanel = _a.setSidePanel, exports.closeTab = _a.closeTab, exports.updateCurrentTabData = _a.updateCurrentTabData, exports.addTab = _a.addTab, exports.setBlockingModal = _a.setBlockingModal, exports.setCurrentTab = _a.setCurrentTab, exports.setPlayerFullScreen = _a.setPlayerFullScreen, exports.addReader = _a.addReader, exports.closeCurrentTab = _a.closeCurrentTab, exports.setSpotlight = _a.setSpotlight, exports.setFeatures = _a.setFeatures, exports.setUpdateCounter = _a.setUpdateCounter, exports.setSaveTab = _a.setSaveTab, exports.setCustomTab = _a.setCustomTab, exports.setShowTask = _a.setShowTask, exports.moveTab = _a.moveTab, exports.closeOther = _a.closeOther;
const selectSideBar = (state) => state.mainframe.sidePanel;
exports.selectSideBar = selectSideBar;
const selectCustomTab = (state) => state.mainframe.customTabs;
exports.selectCustomTab = selectCustomTab;
const selectUpdateCounter = (state) => state.mainframe.updateCounter;
exports.selectUpdateCounter = selectUpdateCounter;
const selectSaveTab = (state) => state.mainframe.saveTabs;
exports.selectSaveTab = selectSaveTab;
const selectShowTask = (state) => state.mainframe.showTask;
exports.selectShowTask = selectShowTask;
const selectFeatures = (state) => state.mainframe.features;
exports.selectFeatures = selectFeatures;
const selectSpotlight = (state) => state.mainframe.spotlight;
exports.selectSpotlight = selectSpotlight;
const selectCurrentTab = (state, pos) => {
    if (pos === 'right') {
        return state.mainframe.tabsRight[state.mainframe.currentTabRight];
    }
    return state.mainframe.tabsLeft[state.mainframe.currentTabLeft];
};
exports.selectCurrentTab = selectCurrentTab;
const selectCurrentTabLeft = (state) => state.mainframe.currentTabLeft;
exports.selectCurrentTabLeft = selectCurrentTabLeft;
const selectCurrentTabRight = (state) => state.mainframe.currentTabRight;
exports.selectCurrentTabRight = selectCurrentTabRight;
const selectPlayerFullScreen = (state) => state.mainframe.playerFullScreen;
exports.selectPlayerFullScreen = selectPlayerFullScreen;
const selectTabsRight = (state) => state.mainframe.tabsRight;
exports.selectTabsRight = selectTabsRight;
const selectTabsLeft = (state) => state.mainframe.tabsLeft;
exports.selectTabsLeft = selectTabsLeft;
const selectBlockingModal = (state) => state.mainframe.blockingModal;
exports.selectBlockingModal = selectBlockingModal;
