"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
function WatchComp({ image, title, meta, compKey, animation, action, noElements, }) {
    const defaultAction = (0, react_1.useMemo)(() => action?.find((a) => a.default), [action]);
    return (react_1.default.createElement("div", { className: (0, react_components_1.cnx)('watch-element', [!!animation, 'watch-element--scale'], [!!defaultAction, 'glx-pointer']), onClick: () => defaultAction?.onClick() },
        meta?.topLeft && react_1.default.createElement("div", { className: "top-left" }, meta.topLeft),
        meta?.unWatched && typeof meta.unWatched === 'number' && (react_1.default.createElement("div", { className: " flex flex-center unwatched" }, meta.unWatched)),
        typeof meta?.unWatched === 'boolean' &&
            (meta?.unWatched ||
                (!meta.unWatched &&
                    typeof meta.progress === 'number' &&
                    meta.progress < 95 &&
                    meta.progress !== 0)) && react_1.default.createElement("div", { className: "unwatched-triangle" }),
        noElements && (react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, className: "watch-element--empty" },
            react_1.default.createElement(react_components_1.IOVideocamOffOutline, { size: react_components_1.ISize.MD }))),
        meta?.unWatched === false &&
            meta?.progress !== undefined &&
            meta?.progress !== null &&
            meta.progress < 95 && (react_1.default.createElement("div", { className: "progress-bar" },
            react_1.default.createElement("div", { style: {
                    width: `${meta.progress}%`,
                }, className: "progress" }))),
        react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, className: [
                'watch-hover',
                [!!action, 'watch-backdrop'],
                [!!defaultAction, 'glx-pointer'],
            ], gap: 4 }, action
            ?.filter((e) => !e.hidden)
            .map((a) => (react_1.default.createElement(react_components_1.IconButton, { key: `${compKey}_${a.key}`, onClick: (event) => {
                event?.stopPropagation();
                a.onClick();
            }, toolTip: {
                text: a.text,
            } }, (0, react_components_1.getIcon)(a.icon)({ size: 28 }))))),
        react_1.default.createElement("div", { style: {
                height: 260,
                width: 180,
            }, className: (0, react_components_1.cnx)('glx-flex-center', [!image, 'animated-background']) }, image && (react_1.default.createElement("img", { loading: "lazy", src: image, alt: title, width: 180, height: 260 }))),
        title && react_1.default.createElement("div", { className: "glx-text-center" }, title)));
}
exports.default = WatchComp;
