"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MarkAsComp;
const react_components_1 = require("@grandlinex/react-components");
const react_1 = __importStar(require("react"));
const react_toastify_1 = require("react-toastify");
const store_1 = require("@/store");
const TabStore_1 = require("@/store/TabStore");
const GlobalContext_1 = require("@/context/GlobalContext");
const TMShow_1 = require("@/lib/TMShow");
function MarkAsComp(props) {
    const { episodes, reload } = props;
    const context = (0, GlobalContext_1.useGlobalContext)();
    const dispatch = (0, store_1.useAppDispatch)();
    const t = (0, store_1.useTranslation)();
    const run = (0, react_1.useCallback)(async (watched) => {
        let cur = 0;
        let err = 0;
        let q = 0;
        const ep = (0, TMShow_1.filterShowMap)(episodes);
        for (const e of ep) {
            cur += 1;
            dispatch((0, TabStore_1.setBlockingModal)({
                message: t.get('page.watch.label.action.running'),
                progress: `${cur} / ${episodes.length} , Error: ${err}`,
            }));
            if (watched) {
                if (!e.progress?.done) {
                    const r = await context.updateShowProgress(e.e_id, {
                        done: true,
                    });
                    if (r.error) {
                        err += 1;
                    }
                    else {
                        q += 1;
                    }
                }
            }
            else if (e.progress) {
                const r = await context.clearShowProgress(e.e_id);
                if (r.error) {
                    err += 1;
                }
                else {
                    q += 1;
                }
            }
        }
        dispatch((0, TabStore_1.setBlockingModal)(null));
        react_toastify_1.toast.info(`Q: ${q}, Error: ${err}, Skipped: ${episodes.length - q - err}`);
        reload();
    }, [context, dispatch, episodes, reload, t]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_components_1.Button, { onClick: () => run(false), text: t.get(`page.watch.label.unwatched.all`) },
            react_1.default.createElement(react_components_1.IOEyeOff, null)),
        react_1.default.createElement(react_components_1.Button, { onClick: () => run(true), text: t.get(`page.watch.label.watched.all`) },
            react_1.default.createElement(react_components_1.IOEye, null))));
}
